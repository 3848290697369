import { Outlet } from "react-router-dom";

import { NavBar } from './components/navbar';
import { Footer } from './components/footer';

function App() {
	return (
		<div id="container" className="bg-white">
			<NavBar/>

			<main className="mt-24">
			 	<Outlet/>
			</main>

			<Footer/>
		</div>
	)
}

export default App;
