import { useRef, useEffect } from 'react';

import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import { createRedirectUrlPlugin } from '@algolia/autocomplete-plugin-redirect-url';
import algoliasearch from 'algoliasearch/lite';

import '@algolia/autocomplete-theme-classic';

const searchClient = algoliasearch('78CP9HC8R1', '45e789862aecab8797d5cb5cca7d4570');

export function Autocomplete(props) {
	const containerRef = useRef(null);
	const panelRootRef = useRef(null);
	const rootRef = useRef(null);

	useEffect(() => {
		if (!containerRef.current) {
			return undefined;
		}

		const search = autocomplete({
			container: containerRef.current,
			placeholder: "Search",
			openOnFocus: true,
			plugins: [createRedirectUrlPlugin],
			getSources({ query }) {
				return [
					{
						sourceId: "demo-source",
						templates: {
							item(params) {
								const { item, html } = params;
								return html`<a href=${"/product/"+item.objectID} class="aa-ItemLink">${item.name}</a>`;
							}
						},
						getItemInputValue({ item }) {
							return item.name;
						},
						getItems() {
							return getAlgoliaResults({
								searchClient,
								queries: [
									{
										indexName: "adott",
										query,
										params: {
											ruleContexts: ["enable-redirect-url"], // note: only needed for this demo data
											hitsPerPage: 10
										}
									}
								]
							});
						}
					}
				]
			}
		});

		return () => {
			search.destroy();
		};
	}, [props]);

	return <div ref={containerRef} />;
}

export function Search() {
	return (
		<Autocomplete />
	);
}