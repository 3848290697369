import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Link, useLocation, Navigate } from "react-router-dom";

import { AuthService } from './common/auth.service';
import { DeliveryLocationProvider } from './common/deliveryLocation';
import { CartProvider } from './common/cart.context';

import './index.css';
import App from './App';
import Guest from './Guest';

import reportWebVitals from './reportWebVitals';

const Overview = React.lazy(() => import('./screens/overview'));
const CategoryPage = React.lazy(() => import('./screens/category'));
const ProductPage = React.lazy(() => import('./screens/product'));
const AboutPage = React.lazy(() => import('./screens/about'));

// guest
const Login = React.lazy(() => import('./screens/guest/login'));
const Register = React.lazy(() => import('./screens/guest/register'));
const GoogleCallback = React.lazy(() => import('./screens/guest/google'));

// accounts
const Settings = React.lazy(() => import('./screens/account/settings'));
const Orders = React.lazy(() => import('./screens/account/orders'));
const OrderDetail = React.lazy(() => import('./screens/account/order_detail'));
const Checkout = React.lazy(() => import('./screens/account/checkout'));
const Cart = React.lazy(() => import('./screens/account/cart'));

function RequireAuth({ children }) {
	let location = useLocation();

	if (!AuthService.currentUserValue) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/guest/login" state={{ from: location }} replace />;
	}

	return children;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<DeliveryLocationProvider>
		<CartProvider>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<App/>}>
						<Route index element={
							<React.Suspense fallback={<>...</>}>
								<Overview/>
							</React.Suspense>
						}/>
						<Route path="category/:id" element={
							<React.Suspense fallback={<>...</>}>
								<CategoryPage/>
							</React.Suspense>
						} />
						<Route path="product/:id" element={
							<React.Suspense fallback={<>...</>}>
								<ProductPage/>
							</React.Suspense>
						} />
						<Route path="about" element={
							<React.Suspense fallback={<>...</>}>
								<AboutPage/>
							</React.Suspense>
						} />
						<Route path="cart" element={
							<React.Suspense fallback={<>...</>}>
								<Cart/>
							</React.Suspense>
						} />
						<Route path="checkout" element={
							<React.Suspense fallback={<>...</>}>
								<Checkout/>
							</React.Suspense>
						} />
						<Route path="link/:id" element={
							<React.Suspense fallback={<>...</>}>
								<Link/>
							</React.Suspense>
						} />
					</Route>
					<Route path="/account" element={
						<RequireAuth>
							<App/>
						</RequireAuth>
					}>
						{/* <Route index element={
							<React.Suspense fallback={<>...</>}>
								<Overview/>
							</React.Suspense>
						}/> */}
						<Route path="settings" element={
							<React.Suspense fallback={<>...</>}>
								<Settings/>
							</React.Suspense>
						} />
						<Route path="orders" element={
							<React.Suspense fallback={<>...</>}>
								<Orders/>
							</React.Suspense>
						} />
						<Route path="order/:id" element={
							<React.Suspense fallback={<>...</>}>
								<OrderDetail/>
							</React.Suspense>
						} />
					</Route>
					<Route path="/guest/" element={
						AuthService.currentUserValue == null ? <Guest/> : <Navigate to="/" replace />
					}>
						<Route path="login" element={
							<React.Suspense fallback={<>...</>}>
								<Login/>
							</React.Suspense>
						} />
						<Route path="register" element={
							<React.Suspense fallback={<>...</>}>
								<Register/>
							</React.Suspense>
						} />
					</Route>
					<Route path="/guest/auth/google/callback" element={
						<React.Suspense fallback={<>...</>}>
							<GoogleCallback/>
						</React.Suspense>
					} />
				</Routes>
			</BrowserRouter>
		</CartProvider>
	</DeliveryLocationProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
