import { BehaviorSubject, last } from 'rxjs';

import axios from 'axios';

import { AUTH_URL  } from './../config';

const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));

async function login(username, password){
	
	return await axios.post(AUTH_URL + "/login", {
		username: username, password: password
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	}).then(function(resp){
		var user = JSON.stringify(resp.data);
		localStorage.setItem('currentUser', user);
		currentUserSubject.next(user);
		return user;
	}).catch((error) => {
		// console.log(error);
		if(error.code == "ERR_NETWORK")
		{
			throw "Unable to Connect to Server";
		}
		else
		{
			// ERR_BAD_REQUEST
			throw error.response.data;
		}
	});
}

async function register(first_name, last_name, email, password){
	
	return await axios.post(AUTH_URL + "/register", {
		first_name: first_name,
		last_name: last_name,
		email: email,
		password: password
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	}).then(function(resp){
		var user = JSON.stringify(resp.data);
		localStorage.setItem('currentUser', user);
		currentUserSubject.next(user);
		return user;
	}).catch((error) => {
		// console.log(error);
		if(error.code == "ERR_NETWORK")
		{
			throw "Unable to Connect to Server";
		}
		else
		{
			// ERR_BAD_REQUEST
			throw error.response.data;
		}
	});
}

async function socailAuth(type, data){
	return await axios.post(AUTH_URL + "/social-callback", {
		type: type,
		data: data
	}, {
		headers: {
			'Content-Type': 'application/json',
			'Accept': 'application/json'
		}
	}).then(function(resp){
		var user = JSON.stringify(resp.data);
		localStorage.setItem('currentUser', user);
		currentUserSubject.next(user);
		return user;
	}).catch((error) => {
		// console.log(error);
		if(error.code == "ERR_NETWORK")
		{
			throw "Unable to Connect to Server";
		}
		else
		{
			// ERR_BAD_REQUEST
			throw error.response.data;
		}
	});
}

function logout(){
	localStorage.removeItem('currentUser');
	// currentUserSubject.next(null);
	window.location.reload();
}

export const AuthService = {
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() {return currentUserSubject.value },
	login,
	register,
	logout,
	socailAuth
};