import { Fragment, useEffect, useState } from 'react'
import { BellIcon } from '@heroicons/react/outline'
import { Popover, Transition } from '@headlessui/react';
import { classNames } from '../libraries/utility';

import { dbCall } from './../common/db';
import { useParams, Link } from "react-router-dom";

export function Notification() {

	const [notifications, setNotifications] = useState([]);

	async function getNotifications() {
		await dbCall.get("/user/notifications").then((response) => {
			// console.log(response.data);
			setNotifications(response.data);
		})
		.catch(function (error) {
			console.log(error);
		})
		.then(function () { });
	}

	useEffect(() => {
		getNotifications();
	}, []);

	function markAsRead() {
		console.log("asdsada");
	}

    return (
        <>
            <Popover className="flow-root text-sm lg:relative">
                <Popover.Button className="group -m-2 p-2 flex items-center" onClick={markAsRead}>
                    <BellIcon className="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
					<span class="absolute top-0 right-0 inline-block w-2 h-2 transform translate-x-1/2 -translate-y-1/2 bg-amber-500 rounded-full"></span>
                    <span className="sr-only">notifications</span>
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Popover.Panel className="absolute top-16 inset-x-0 mt-px pb-6 bg-white shadow-lg sm:px-2 lg:top-full lg:left-auto lg:right-0 lg:mt-3 lg:-mr-1.5 lg:w-80 lg:rounded-lg lg:ring-1 lg:ring-black lg:ring-opacity-5">
                        <h2 className="sr-only">Notifications</h2>

                        <form className="max-w-2xl mx-auto px-4">
                            <ul role="list" className="divide-y divide-gray-200">
                                {notifications?.map((notification) => (
                                    <li key={notification.id} className="py-3 flex items-center">
                                        
                                        <div className="flex-auto">
                                            <h3 className={classNames(
                                            	notification.read == false
                                                	? 'font-medium text-amber-600'
                                                	: 'font-medium text-gray-900'
                                        	)}>
												{notification?.Order?.uniqueId ? 
													<Link to={"/account/order/" + notification?.Order?.uniqueId}>{notification.title}</Link>
													:
													<p>{notification.title}</p>
												}
                                            </h3>
                                            <p className="text-gray-500">{notification.message}</p>
                                        </div>
                                    </li>
                                ))}
                            </ul>

                            {/* <p className="mt-2 text-center">
                                <a href="#" className="text-sm font-medium text-indigo-600 hover:text-indigo-500">
                                    View All Notifications
                                </a>
                            </p> */}
                        </form>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </>
    );
}