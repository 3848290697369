import { Fragment, useEffect, useState } from 'react';
import { UsersIcon, LocationMarkerIcon, MapIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { Combobox, Dialog, Transition, RadioGroup } from '@headlessui/react';
import { Link } from "react-router-dom";

import { dbCall } from './../common/db';
import { AuthService } from './../common/auth.service';
import { DeliveryLocation } from './../common/deliveryLocation';
import { classNames } from './../libraries/utility';


const plans = [
	{ name: 'Hobby', ram: '8GB', cpus: '4 CPUs', disk: '160 GB SSD disk', price: '$40' },
	{ name: 'Startup', ram: '12GB', cpus: '6 CPUs', disk: '256 GB SSD disk', price: '$80' },
	{ name: 'Business', ram: '16GB', cpus: '8 CPUs', disk: '512 GB SSD disk', price: '$160' },
	{ name: 'Enterprise', ram: '32GB', cpus: '12 CPUs', disk: '1024 GB SSD disk', price: '$240' },
];


export function Location() {

	const _deliveryLocation = DeliveryLocation();

	const [open, setOpen] = useState(false);

	const [userAddresses, setUserAddresses] = useState([]);
	const [defaultAddressId, setDefaultAddressId] = useState(null);
	const [userZip, setUserZip] = useState(null);

	async function fetchUserAddresses() {
		await dbCall.get("/user/address/all").then((response) => {
			setUserAddresses(response.data);
		})
			.catch(function (error) {
				console.log(error);
			})
			.then(function () { });
	}

	async function fetchDefaultAddress() {
		await dbCall.get("/user/address/default").then((response) => {
			console.log(response.data);
			setDefaultAddressId(response.data);
		})
			.catch(function (error) {
				console.log(error);
			})
			.then(function () { });
	}

	useEffect(() => {
		if (open == true) {
			if(AuthService.currentUserValue != null)
			{
				fetchDefaultAddress();
				fetchUserAddresses();
			}
		}
	}, [open]);

	// useEffect(() => {
	// 	alert("Asdsadsa");
	// 	var _d = _deliveryLocation.isExists();
	// 	if(_d == false)
	// 	{
	// 		fetchDefaultAddress();
	// 		fetchUserAddresses();
	// 	}
	// }, []);



	async function setAsDeliveryAddress(name, city, zip, id) {
		_deliveryLocation.set(name, city, zip, id);
		setOpen(false);
	}

	async function setZipAsAddress() {

		await dbCall.get("/location/search?zip="+userZip).then((response) => {
			if(response?.data)
			{
				_deliveryLocation.set(response.data.name, response.data.city, response.data.zip);
				setOpen(false);
			}
			else
			{
				alert("Not Found");
			}
		})
		.catch(function (error) {
			console.log(error);
			alert(error);
		})
		.then(function () { });
	}


	return (
		<>
			<div className="hidden lg:flex">
				<button type="button" className="text-gray-700 hover:text-gray-800 flex items-center" onClick={() => setOpen(true)} title="Deliver to Location">
					<div className="font-medium">
						<LocationMarkerIcon className="h-6 w-6 text-gray-400 inline mr-1" aria-hidden="true" />
						{_deliveryLocation.current != null ?
						<span className="text-sm">{ _deliveryLocation.current.name != null ? _deliveryLocation.current.name + " - ": null } {_deliveryLocation.current.city} {_deliveryLocation.current.zip}</span> : <span className="text-sm">Select Your Location</span>}
					</div>
					<span className="sr-only">change location</span>
				</button>
			</div>

			<div className="lg:hidden">
				<button type="button" className="text-gray-700 hover:text-gray-800 flex items-center" onClick={() => setOpen(true)} title="Deliver to Location">
					<div className="font-medium">
						<LocationMarkerIcon className="h-6 w-6 text-gray-400 inline mr-1" aria-hidden="true" />
						{_deliveryLocation.current != null ?
						<span className="text-sm">{ _deliveryLocation.current.name != null ? _deliveryLocation.current.name + " - ": null } {_deliveryLocation.current.city} {_deliveryLocation.current.zip}</span> : null}
					</div>
					<span className="sr-only">change location</span>
				</button>
			</div>

			<Transition.Root show={open} as={Fragment}>
				<Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20" onClose={setOpen}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<Combobox
							as="div"
							className="mx-auto max-w-xl transform rounded-xl bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
							onChange={(person) => (window.location = person.url)}
						>

							<div className="py-3">
								<p className="inline font-medium text-gray-600">Choose your Location</p>
								<p className="text-gray-500 mt-1">
									Select a delivery location to see product availability and delivery options
								</p>
							</div>

							{AuthService.currentUserValue != null ?
							<>
							<div className="bg-white border shadow overflow-hidden sm:rounded-md">
								<ul role="list" className="divide-y divide-gray-200">
									{userAddresses.map((address, index) => (
										<li key={index} className="cursor-pointer" onClick={() => setAsDeliveryAddress(address.name ,address.city, address.zipCode,address.id)}>
											<div className={
												classNames(
													_deliveryLocation.current?.id == address.id ? 'bg-lime-200': null,
													"block hover:bg-lime-200"
												)
											}>
												<div className="px-4 py-4 sm:px-6">
													<div className="flex items-center">
														<p className="text-sm font-medium text-amber-600 truncate uppercase">{address.name}</p>
													</div>
													<div className="mt-1 sm:flex sm:justify-between">
														<div className="sm:flex">
															<p className="flex items-center text-sm text-gray-500">
																<p>
																	{address.address} {address.street}&nbsp;
																	{address.city}, {address.province} {address.zipCode}&nbsp;
																	<span className="inline-flex capitalize">{address.country}</span>
																	<br/>
																	{defaultAddressId == address.id ?
																	<p className="mt-1 inline-flex text-xs leading-5 font-semibold rounded-full text-gray-400 uppercase">
																		default
																	</p> : null}
																</p>
															</p>
														</div>
													</div>
												</div>
											</div>
										</li>
									))}
								</ul>
							</div>

							<div className="mt-4">
								<Link to="/account/settings" className="text-amber-700 text-md">
									Add address or pick-up point
								</Link>
							</div>
							</>: 
							
							
							<Link
								to="/guest/login"
								className="mt-2 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-amber-700 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
							>
								Sign in to see your addresses
							</Link>

							}

							<div className="mt-4">
								<div className="relative">
									<div className="absolute inset-0 flex items-center">
										<div className="w-full border-t border-gray-300" />
									</div>
									<div className="relative flex justify-center text-sm">
										<span className="px-2 bg-white text-gray-500">or enter a Zip Code</span>
									</div>
								</div>

								<div className="mt-2 flex rounded-md shadow-sm">
									<div className="relative flex items-stretch flex-grow focus-within:z-10">
										<input
											type="text"
											name="zip"
											id="zip"
											value={userZip}
											onChange={(e) => setUserZip(e.target.value)}
											className="focus:ring-amber-500 focus:border-amber-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
											placeholder=""
										/>
									</div>
									<button
										type="button"
										onClick={() => setZipAsAddress()}
										className="-ml-px relative inline-flex items-center space-x-2 px-10 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-amber-500 focus:border-amber-500"
									>
										<span>Apply</span>
									</button>
								</div>
							</div>
						</Combobox>
					</Transition.Child>
				</Dialog>
			</Transition.Root>
		</>
	);
}