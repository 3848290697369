import React, {createContext, useState, useContext, useEffect} from 'react';
import store from 'store2';

const DeliveryLocationContext = createContext({});

const _delivery_location_key = "delivery_location";

const DeliveryLocationProvider = ({children}) => {

	const [currentDeliveryLocation, setCurrentDeliveryLocation] = useState(null);

	// on app load, check exists on local storage
	useEffect(() => {
		checkDeliveryLocation();
	},[]);

	// check
	async function checkDeliveryLocation() {
		if(store.has(_delivery_location_key))
		{
			var _data = store.get(_delivery_location_key);
			setCurrentDeliveryLocation(_data);
		}

		console.log("checking existing");
	}

	// add or update
	async function setDeliveryLocation(name, city, zip, id = null) {
		// TODO
		// search nearest store and set store id
		
		var _data = {
			"name": name,
			"id": id,
			"zip": zip,
			"city": city,
			"store_id": "llcgsrvwhx1mu35rqqg",
			"distance": 5.8		
		};
		
		store.set(_delivery_location_key, _data);
		setCurrentDeliveryLocation(_data);
	}

	async function removeIdAndName() {
		if(store.has(_delivery_location_key))
		{
			var _data = {
				"name": null,
				"id": null,
				"zip": store.get(_delivery_location_key).zip,
				"city": store.get(_delivery_location_key).city,
				"store_id": "llcgsrvwhx1mu35rqqg",
				"distance": 5.8
			};
			store.set(_delivery_location_key, _data);
			setCurrentDeliveryLocation(_data);
		}
	}

	// remove
	async function removeDeliveryLocation() {
		store.remove(_delivery_location_key);
		setCurrentDeliveryLocation(null);
	}

	function isDeliveryLocationExists() {
		return store.has(_delivery_location_key);
	}

	const deliveryLocation = {
		current: currentDeliveryLocation,
		check: checkDeliveryLocation,
		set: setDeliveryLocation,
		remove: removeDeliveryLocation,
		isExists: isDeliveryLocationExists,
		removeIdAndName: removeIdAndName
	};

	return(
		<DeliveryLocationContext.Provider value={deliveryLocation}>
			{children}
		</DeliveryLocationContext.Provider>
	)
}

function DeliveryLocation() {
	const context = useContext(DeliveryLocationContext);

	if(!context) {
		throw new Error('must be within Provider');
	}

	return context;
}

export {DeliveryLocationContext, DeliveryLocationProvider, DeliveryLocation};